.custom-quantity-selector {
    $this: &;

    justify-content: center;

    #{$this}__input {
        font-family: var(--skin-bold-font);
        height: 40px;
        width: 40px;
        border-color: var(--skin-primary-color-1);
        border-radius: 0;
        color: var(--skin-primary-color-1);
        padding-inline: 0;
        -moz-appearance: textfield;

        &:focus {
            color: var(--skin-primary-color-1);
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        @include media-breakpoint-up(sm) {
            width: 48px;
            height: 48px;
        }
    }

    // Increase specificity
    #{$this}__control {
        font-family: var(--skin-bold-font);
        font-size: 1.25em;
        line-height: 1;
        height: 40px;
        width: 40px;
        padding: 0;
        opacity: 1;
        cursor: pointer;

        &[data-type=minus] {
            border-radius: 50% 0 0 50%;
            border-right-style: none;
            z-index: 1;
        }

        &[data-type=plus] {
            border-radius: 0 50% 50% 0;
            border-left-style: none;
        }

        &:disabled,
        &.disabled {
            background-color: var(--bs-gray-200);
            cursor: not-allowed;

            &:hover,
            &:focus,
            &.active {
                color: var(--skin-primary-color-1);
            }
        }

        @include media-breakpoint-up(sm) {
            width: 48px;
            height: 48px;
        }
    }
}

.quantity-validation-error {
    @include media-breakpoint-up(sm) {
        text-align: right;
    }
}
