@import "~base/components/breadcrumb";

.breadcrumb {
    --bs-breadcrumb-divider-color: #6c757d;
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: #6c757d;

    display: flex;
    flex-wrap: wrap;
    padding: 18px 0 0;
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius);
    margin-left: 0 !important;
}

.container .breadcrumb {
    padding-bottom: 0;
    padding-top: 16px;
    margin-bottom: 0;
    border-bottom: none;

    @include media-breakpoint-down(xs) {
        padding-top: 12px;
    }
}

.breadcrumb ol {
    margin: 0;
    padding: 0;
}

[dir=rtl] .breadcrumb ol {
    margin-right: 0;
}

.breadcrumb li {
    display: inline;
    position: relative;
    font-size: 14px;
    padding: 0 30px 0 15px;
    margin: 0;
    list-style-type: none;

    &:last-of-type {
        padding-right: 0;
    }
}

.breadcrumb li::before {
    content: " \BB ";
}

.breadcrumb li:first-child::before {
    content: none;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: none;
}

.breadcrumb-item.active,
.media-library-view--widget .view-header a.breadcrumb-item.is-active {
    color: var(--bs-breadcrumb-item-active-color)
}

div.block-system-breadcrumb-block {
    padding: 0 20px;
}

.breadcrumb.breadcrumb-sm {
    display: block;
}

.breadcrumb li::after {
    content: "\276F";
    position: absolute;
    right: -6px;
    top: 0;
    color: var(--skin-primary-color-1);
    padding-right: 18px;
}

.breadcrumb li.current {
    top: 2px;
}

.breadcrumb li.current::after {
    content: "";
}

.breadcrumb.breadcrumb-sm li {
    padding-left: 20px;
}

.breadcrumb.breadcrumb-sm li::after {
    content: "\276E";
    left: 0;
    top: 0;
}

.breadcrumb li:last-child::after {
    display: none;
}

body .breadcrumb li a {
    color: var(--skin-primary-color-1);
}

body .breadcrumb li a:hover {
    text-decoration: underline;
    color: var(--skin-primary-color-1);
}

@media (min-width: 769px) {
    .breadcrumb {
        display: block;
    }

    .breadcrumb.breadcrumb-sm {
        display: none;
    }

    div.block-system-breadcrumb-block {
        padding: 0 60px;
    }
}

@media print {
    #block-unified-breadcrumbs {
        display: none;
    }
}

.breadcrumb .breadcrumb-item:not(:last-child) {
    color: var(--skin-primary-color-1);
    padding-left: 0;
}

.breadcrumb .breadcrumb-item:first-child {
    padding-left: 0;
}

.page-product ul.breadcrumb {
    position: relative;
    z-index: 1;
}
