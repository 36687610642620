@import "~base/variables";

$container-max-widths: (
    sm: 544px,
    md: 769px,
    lg: 992px,
    xl: 1200px
);
$container-padding-x: 20px;
$page-max-width: 1440px;
$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
$svg-radio: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%231A818D'/%3e%3c/svg%3e");
$custom-select-background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24'%3E%3Cpath fill='%230B393E' d='M19.1992 9.35367L12.4921 16.0608L5.78501 9.35367L7.19922 7.93945L12.4921 13.2323L17.785 7.93945L19.1992 9.35367Z'/%3E%3C/svg%3E") right 1rem center no-repeat;
$svg-trash-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M14.7692 2C15.3895 2 15.8963 2.46175 15.9226 3.04089V4.82658H20.1306C20.6108 4.82658 21 5.21581 21 5.69595C21 6.17609 20.6108 6.56532 20.1306 6.56532H19.5808L18.3374 20.9852C18.3126 21.2532 18.1856 21.5036 17.98 21.6902C17.7743 21.8768 17.5038 21.9868 17.2186 22H6.83262C6.54778 21.9983 6.27321 21.8996 6.05948 21.7223C5.84575 21.5449 5.70727 21.3007 5.66954 21.0348L5.66262 20.9865L4.41923 6.56662H3.87002C3.38952 6.56662 3 6.1771 3 5.6966C3 5.2161 3.38952 4.82658 3.87002 4.82658H8.07738V3.08785C8.07738 2.50349 8.56615 2.02609 9.18092 2.0013L14.7692 2.0013V2ZM17.7282 6.56532L6.27185 6.56532L7.45431 20.2613H16.5457L17.7282 6.56532ZM15 15.5L15.0004 10.3155C15.0004 9.80541 14.587 9.3919 14.0769 9.3919C13.5669 9.3919 13.1534 9.80537 13.1534 10.3154V16.7304C13.1534 17.2404 13.5668 17.6538 14.0768 17.6538C14.5869 17.6538 15.0004 17.2403 15.0003 16.7302L15 15.5ZM9.92308 9.3919C10.4331 9.3919 10.8466 9.80538 10.8466 10.3154L10.8466 16.7303C10.8466 17.2403 10.4331 17.6538 9.92308 17.6538C9.41302 17.6538 8.99954 17.2403 8.99954 16.7303L8.99954 10.3154C8.99954 9.80538 9.41302 9.3919 9.92308 9.3919ZM9.92308 3.74004L14.0769 3.74004V4.82658L9.92308 4.82658V3.74004Z' fill='%231A818D'/%3E%3C/svg%3E") left 0 center no-repeat;
