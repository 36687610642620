@import "~base/components/toastMessage";

@mixin custom-toast-message() {
    transform: translateX(-50%);
    position: fixed;
    top: calc(var(--header-height-with-search, 15%) + 2em);
    left: 50%;
    z-index: 99;
}

@mixin toast-alert-extended() {
    animation: fade 8s linear forwards;
    box-shadow: 1px 1px 5px grey;
    padding: 1em;

    @keyframes fade {
        0% { opacity: 0; }
        10% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
    }

    &.show {
        display: block;
    }
}
