@import "~base/components/header";
@import "menu";

.unified-header {
    .tbm {
        position: relative;
    }

    .tbm [class^='fa-'],
    .tbm [class*=' fa-'] {
        margin-right: 0.25rem;
    }

    .tbm-nav,
    [dir='ltr'] .tbm-nav {
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .tbm-row {
        -moz-column-gap: 0.625rem;
        column-gap: 0.625rem;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }

    .tbm-row [class*='span'] {
        min-height: 2rem;
    }

    .tbm-row .span12 {
        grid-column-start: span 12;
    }

    .tbm-link.level-1 {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
    }

    .tbm-link-container {
        align-items: stretch;
        display: flex;
        height: 100%;
    }

    .tbm-submenu {
        box-sizing: border-box;
        display: none;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 10;
    }

    .tbm-subnav {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .tbm-subnav>.tbm-item {
        display: block;
        list-style: none;
    }

    .tbm.tbm--mobile .always-show .tbm-submenu {
        display: block !important;
    }

    .tbm.tbm--mobile .tbm-row {
        display: block;
    }

    .tbm.tbm--mobile .tbm-collapse {
        display: none;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 999;
    }

    .tbm.tbm--mobile .tbm-nav {
        flex-direction: column;
    }

    .tbm.tbm--mobile .tbm-submenu {
        position: static;
        margin: 0;
        padding: 0;
        border: 0;
        width: 100% !important;
    }

    .tbm {
        background-color: #f5f5f5;
    }

    .tbm-item.level-1 {
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    .tbm-link.level-1 {
        color: #222;
        padding: 1rem 1.25rem;
    }

    .tbm-link.level-1:hover,
    .tbm-link.level-1:focus {
        background-color: #e8e8e8;
    }

    .tbm-submenu {
        background-color: #fff;
        border-radius: 0;
        border-top-width: 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        padding: 1rem;
    }

    .tbm-submenu .tbm-submenu {
        border-top-width: 1px;
    }

    .tbm.tbm--mobile {
        background-color: transparent;
    }

    .tbm.tbm--mobile .tbm-item {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    .tbm.tbm--mobile .tbm-collapse {
        background: #fff;
        padding-top: 1rem;
    }

    .tbm.tbm--mobile .tbm-nav {
        background: #f5f5f5;
    }

    .tbm.tbm--mobile .tbm-submenu {
        box-shadow: none;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    .tbm.tbm--mobile .tbm-submenu .tbm-link {
        padding: 1rem 1.25rem;
    }

    .tbm.tbm--mobile .tbm-subnav .tbm-item:first-child {
        border-top: 0;
    }

    .tbm.tbm--mobile .tbm-subnav .tbm-subnav {
        margin-left: 1rem;
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    a {
        background-color: transparent;
    }

    img {
        border-style: none;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
    }

    button,
    input {
        overflow: visible;
    }

    button,
    select {
        text-transform: none;
    }

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        -webkit-appearance: button;
    }

    [type='checkbox'],
    [type='radio'] {
        box-sizing: border-box;
        padding: 0;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    .form-item,
    .form-actions {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    *,
    ::after,
    ::before {
        box-sizing: border-box;
    }

    @media (prefers-reduced-motion: no-preference) {
        :root {
            scroll-behavior: smooth;
        }
    }

    hr {
        margin: 1rem 0;
        color: inherit;
        border: 0;
        border-top: 1px solid;
        opacity: 0.25;
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    .h2,
    h2 {
        font-size: calc(1.325rem + 0.9vw);
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol,
    ul {
        padding-left: 2rem;
    }

    dl,
    ol,
    ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
        margin-bottom: 0;
    }

    a {
        color: var(--bs-link-color);
        text-decoration: underline;
    }

    img,
    svg {
        vertical-align: middle;
    }

    table {
        caption-side: bottom;
        border-collapse: collapse;
    }

    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }

    label {
        display: inline-block;
    }

    button {
        border-radius: 0;
    }

    button:focus:not(:focus-visible) {
        outline: 0;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    button,
    select {
        text-transform: none;
    }

    [role='button'] {
        cursor: pointer;
    }

    select {
        word-wrap: normal;
    }

    [type='button'],
    [type='reset'],
    [type='submit'],
    button {
        -webkit-appearance: button;
    }

    [type='button']:not(:disabled),
    [type='reset']:not(:disabled),
    [type='submit']:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    iframe {
        border: 0;
    }

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-right: auto;
        margin-left: auto;
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
    }

    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        margin-top: var(--bs-gutter-y);
    }

    .col-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    @media (min-width: 544px) {
        .offset-sm-2 {
            margin-left: 16.66666667%;
        }
    }

    :root {
        --bs-table-color: var(--bs-body-color);
        --bs-table-bg: transparent;
        --bs-table-border-color: var(--bs-border-color);
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: var(--bs-body-color);
        --bs-table-striped-bg: #f9f9f9;
        --bs-table-active-color: var(--bs-body-color);
        --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        --bs-table-hover-color: var(--bs-body-color);
        --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    }

    .form-text {
        margin-top: 0;
        font-size: 0.875em;
        color: #6c757d;
    }

    .form-control {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .form-control:focus {
        color: #212529;
        background-color: #fff;
        border-color: #809ee2;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(0, 60, 197, 0.25);
    }

    .form-control::placeholder {
        color: #6c757d;
        opacity: 1;
    }

    :root {
        --bs-btn-padding-x: 0.75rem;
        --bs-btn-padding-y: 0.375rem;
        --bs-btn-font-size: 1rem;
        --bs-btn-font-weight: 400;
        --bs-btn-line-height: 1.5;
        --bs-btn-color: #212529;
        --bs-btn-bg: transparent;
        --bs-btn-border-width: 1px;
        --bs-btn-border-color: transparent;
        --bs-btn-border-radius: 0.375rem;
        --bs-btn-hover-border-color: transparent;
        --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
        --bs-btn-disabled-opacity: 0.65;
        --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
    }

    .btn,
    .button,
    .node-preview-container .node-preview-backlink,
    a.button,
    button.link {
        display: inline-block;
        padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
        font-family: var(--bs-btn-font-family);
        font-size: var(--bs-btn-font-size);
        font-weight: var(--bs-btn-font-weight);
        line-height: var(--bs-btn-line-height);
        color: var(--bs-btn-color);
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
        border-radius: var(--bs-btn-border-radius);
        background-color: var(--bs-btn-bg);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .btn:hover,
    .button:hover,
    .node-preview-container .node-preview-backlink:hover,
    button.link:hover {
        color: var(--bs-btn-hover-color);
        background-color: var(--bs-btn-hover-bg);
        border-color: var(--bs-btn-hover-border-color);
    }

    .btn-primary {
        --bs-btn-color: #fff;
        --bs-btn-bg: #003cc5;
        --bs-btn-border-color: #003cc5;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #0033a7;
        --bs-btn-hover-border-color: #00309e;
        --bs-btn-focus-shadow-rgb: 38, 89, 206;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #00309e;
        --bs-btn-active-border-color: #002d94;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #003cc5;
        --bs-btn-disabled-border-color: #003cc5;
    }

    .btn-light {
        --bs-btn-color: #000;
        --bs-btn-bg: #f8f9fa;
        --bs-btn-border-color: #f8f9fa;
        --bs-btn-hover-color: #000;
        --bs-btn-hover-bg: #d3d4d5;
        --bs-btn-hover-border-color: #c6c7c8;
        --bs-btn-focus-shadow-rgb: 211, 212, 213;
        --bs-btn-active-color: #000;
        --bs-btn-active-bg: #c6c7c8;
        --bs-btn-active-border-color: #babbbc;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #000;
        --bs-btn-disabled-bg: #f8f9fa;
        --bs-btn-disabled-border-color: #f8f9fa;
    }

    .btn-outline-primary,
    .node-preview-container .node-preview-backlink {
        --bs-btn-color: #003cc5;
        --bs-btn-border-color: #003cc5;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #003cc5;
        --bs-btn-hover-border-color: #003cc5;
        --bs-btn-focus-shadow-rgb: 0, 60, 197;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #003cc5;
        --bs-btn-active-border-color: #003cc5;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #003cc5;
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #003cc5;
        --bs-gradient: none;
    }

    .fade {
        transition: opacity 0.15s linear;
    }

    .fade:not(.show) {
        opacity: 0;
    }

    .dropdown,
    .dropdown-center,
    .dropend,
    .dropstart,
    .dropup,
    .dropup-center {
        position: relative;
    }

    .dropdown-toggle {
        white-space: nowrap;
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }

    .dropdown-menu {
        --bs-dropdown-zindex: 1000;
        --bs-dropdown-min-width: 10rem;
        --bs-dropdown-padding-x: 0;
        --bs-dropdown-padding-y: 0.5rem;
        --bs-dropdown-spacer: 0.125rem;
        --bs-dropdown-font-size: 1rem;
        --bs-dropdown-color: #212529;
        --bs-dropdown-bg: #fff;
        --bs-dropdown-border-color: var(--bs-border-color-translucent);
        --bs-dropdown-border-radius: 0.375rem;
        --bs-dropdown-border-width: 1px;
        --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
        --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
        --bs-dropdown-divider-margin-y: 0.5rem;
        --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        --bs-dropdown-link-color: #212529;
        --bs-dropdown-link-hover-color: #1e2125;
        --bs-dropdown-link-hover-bg: #e9ecef;
        --bs-dropdown-link-active-color: #fff;
        --bs-dropdown-link-active-bg: #003cc5;
        --bs-dropdown-link-disabled-color: #adb5bd;
        --bs-dropdown-item-padding-x: 1rem;
        --bs-dropdown-item-padding-y: 0.25rem;
        --bs-dropdown-header-color: #6c757d;
        --bs-dropdown-header-padding-x: 1rem;
        --bs-dropdown-header-padding-y: 0.5rem;
        position: absolute;
        z-index: var(--bs-dropdown-zindex);
        display: none;
        min-width: var(--bs-dropdown-min-width);
        padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
        margin: 0;
        font-size: var(--bs-dropdown-font-size);
        color: var(--bs-dropdown-color);
        text-align: left;
        list-style: none;
        background-color: var(--bs-dropdown-bg);
        background-clip: padding-box;
        border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
        border-radius: var(--bs-dropdown-border-radius);
    }

    .btn-group,
    .btn-group-vertical {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
    }

    .btn-group {
        border-radius: 0.375rem;
    }

    .media-library-view--widget .view-header a,
    .nav-link {
        display: block;
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
        font-size: var(--bs-nav-link-font-size);
        font-weight: var(--bs-nav-link-font-weight);
        color: var(--bs-nav-link-color);
        text-decoration: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out;
    }

    .media-library-view--widget .view-header a:focus,
    .media-library-view--widget .view-header a:hover,
    .nav-link:focus,
    .nav-link:hover {
        color: var(--bs-nav-link-hover-color);
    }

    .navbar-nav {
        --bs-nav-link-padding-x: 0;
        --bs-nav-link-padding-y: 0.5rem;
        --bs-nav-link-color: var(--bs-navbar-color);
        --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
        --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .card,
    .form-item-upload,
    .form-type-managed-file,
    .media-library-selection .media-library-item.media-library-item--grid,
    .view-media-library .media-library-views-form .media-library-item--grid .views-field-rendered-entity,
    fieldset.form-item {
        --bs-card-spacer-y: 1rem;
        --bs-card-spacer-x: 1rem;
        --bs-card-title-spacer-y: 0.5rem;
        --bs-card-border-width: 1px;
        --bs-card-border-color: var(--bs-border-color-translucent);
        --bs-card-border-radius: 0.375rem;
        --bs-card-inner-border-radius: calc(0.375rem - 1px);
        --bs-card-cap-padding-y: 0.5rem;
        --bs-card-cap-padding-x: 1rem;
        --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
        --bs-card-bg: #fff;
        --bs-card-img-overlay-padding: 1rem;
        --bs-card-group-margin: 0.75rem;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        height: var(--bs-card-height);
        word-wrap: break-word;
        background-color: var(--bs-card-bg);
        background-clip: border-box;
        border: var(--bs-card-border-width) solid var(--bs-card-border-color);
        border-radius: var(--bs-card-border-radius);
    }

    .card-body,
    .form-item-upload>div,
    .form-type-managed-file>div,
    .media-library-selection .media-library-item.media-library-item--grid article,
    .media-library-selection .media-library-item.media-library-item--grid>div,
    .view-media-library .media-library-views-form .media-library-item--grid .views-field-rendered-entity,
    fieldset.form-item>.fieldset-wrapper {
        flex: 1 1 auto;
        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
        color: var(--bs-card-color);
    }

    .modal:not(.menu-modal) {
        --bs-modal-zindex: 1055;
        --bs-modal-width: 500px;
        --bs-modal-padding: 1rem;
        --bs-modal-margin: 0.5rem;
        --bs-modal-bg: #fff;
        --bs-modal-border-color: var(--bs-border-color-translucent);
        --bs-modal-border-width: 1px;
        --bs-modal-border-radius: 0.5rem;
        --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        --bs-modal-inner-border-radius: calc(0.5rem - 1px);
        --bs-modal-header-padding-x: 1rem;
        --bs-modal-header-padding-y: 1rem;
        --bs-modal-header-padding: 1rem 1rem;
        --bs-modal-header-border-color: var(--bs-border-color);
        --bs-modal-header-border-width: 1px;
        --bs-modal-title-line-height: 1.5;
        --bs-modal-footer-gap: 0.5rem;
        --bs-modal-footer-border-color: var(--bs-border-color);
        --bs-modal-footer-border-width: 1px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--bs-modal-zindex);
        display: none;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;

        .modal-dialog {
            position: relative;
            width: auto;
            margin: var(--bs-modal-margin);
            pointer-events: none;
        }

        .modal.fade .modal-dialog {
            transition: transform 0.3s ease-out;
            transform: translate(0, -50px);
        }

        .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            color: var(--bs-modal-color);
            pointer-events: auto;
            background-color: var(--bs-modal-bg);
            background-clip: padding-box;
            border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
            border-radius: var(--bs-modal-border-radius);
            outline: 0;
        }

        .modal-header {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            padding: var(--bs-modal-header-padding);
            border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
            border-top-left-radius: var(--bs-modal-inner-border-radius);
            border-top-right-radius: var(--bs-modal-inner-border-radius);
        }

        .modal-title {
            margin-bottom: 0;
            line-height: var(--bs-modal-title-line-height);
        }

        .modal-body {
            position: relative;
            flex: 1 1 auto;
            padding: var(--bs-modal-padding);
        }

        .modal-footer {
            display: flex;
            flex-shrink: 0;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
            background-color: var(--bs-modal-footer-bg);
            border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
            border-bottom-right-radius: var(--bs-modal-inner-border-radius);
            border-bottom-left-radius: var(--bs-modal-inner-border-radius);
        }

        .modal-footer>* {
            margin: calc(var(--bs-modal-footer-gap) * 0.5);
        }
    }

    @media (min-width: 544px) {
        .modal:not(.menu-modal) {
            --bs-modal-margin: 1.75rem;
            --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        }
    }

    .popover {
        z-index: var(--bs-popover-zindex);
        max-width: var(--bs-popover-max-width);
        font-family: var(--bs-font-sans-serif);
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        white-space: normal;
        word-spacing: normal;
        line-break: auto;
        font-size: var(--bs-popover-font-size);
        word-wrap: break-word;
        background-color: var(--bs-popover-bg);
        background-clip: padding-box;
        border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
        border-radius: var(--bs-popover-border-radius);
    }

    .clearfix::after {
        display: block;
        clear: both;
        content: '';
    }

    .visually-hidden,
    .visually-hidden-focusable:not(:focus):not(:focus-within) {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }

    .d-block {
        display: block !important;
    }

    .d-flex {
        display: flex !important;
    }

    .d-none {
        display: none !important;
    }

    .flex-row {
        flex-direction: row !important;
    }

    .flex-column {
        flex-direction: column !important;
    }

    .flex-wrap {
        flex-wrap: wrap !important;
    }

    .justify-content-start {
        justify-content: flex-start !important;
    }

    .justify-content-end {
        justify-content: flex-end !important;
    }

    .align-items-start {
        align-items: flex-start !important;
    }

    @media (min-width: 544px) {
        .d-sm-none {
            display: none !important;
        }

        .mb-sm-3 {
            margin-bottom: 1rem !important;
        }
    }

    @media (min-width: 992px) {
        .d-lg-block {
            display: block !important;
        }

        .d-lg-none {
            display: none !important;
        }

        .flex-lg-row {
            flex-direction: row !important;
        }
    }

    .btn,
    .button,
    .node-preview-container .node-preview-backlink,
    a.button,
    button.link {
        margin: 0 0.5rem;
    }

    .btn:first-child,
    .button:first-child,
    .node-preview-container .node-preview-backlink:first-child,
    button.link:first-child {
        margin-left: 0;
    }

    .btn:last-child,
    .button:last-child,
    .node-preview-container .node-preview-backlink:last-child,
    button.link:last-child {
        margin-right: 0;
    }

    .media-library-view--widget .view-header .navbar-nav a,
    .navbar-nav .media-library-view--widget .view-header a,
    .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    table {
        caption-side: unset;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .alert {
        padding: 12px 64px 12px 20px;
        margin-bottom: 1rem;
        border-radius: 20px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a {
        display: inline;
        text-decoration: none;
        color: var(--skin-primary-color-1);
        transition: color ease-in-out 0.5s;
    }

    a.tbm-toggle:hover {
        text-decoration: underline;
        color: var(--skin-primary-color-1);
        transition: color ease-in-out 0.5s;
    }

    @media (min-width: 769px) {
        ::-webkit-scrollbar {
            width: 8px;
        }
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
        font-weight: 400;
        line-height: 1.1;
        font-family: var(--skin-medium-font);
        margin-bottom: 12px;
        color: var(--skin-primary-color-1);
    }

    h2,
    .h2 {
        font-size: 32px;
    }

    .container {
        padding: 0 20px;
    }

    .container-fluid {
        padding: 0;
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1096px;
        }
    }

    .btn {
        font-family: var(--skin-medium-font);
    }

    .outline-text,
    .btn-outline-primary {
        background-color: var(--skin-primary-color-invert-1);
        border: 1px solid var(--skin-primary-color-1);
        border-radius: 30px;
        text-decoration: none;
        color: var(--skin-primary-color-1);
        text-transform: capitalize;
        padding: 10px 32px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        transition: background-color ease-in-out 0.5s;
    }

    .btn.btn-primary {
        background: var(--skin-primary-color-1);
        border-radius: 40px;
        padding: 10px 35px;
        border: none;
        box-shadow: none;
        color: var(--skin-primary-color-invert-1);
        border: 1px solid var(--skin-primary-color-1);
    }

    .btn.btn-primary:hover {
        background-color: var(--skin-primary-color-invert-2);
        color: var(--skin-primary-color-invert-1);
        text-decoration: none;
    }

    button:focus:not(:focus-visible) {
        outline: 0;
        box-shadow: none;
    }

    .header-above {
        background-color: var(--skin-primary-color-1);
        padding: 5px 20px;
        border-radius: 25px 25px 0 0;
        min-height: 30px;
        display: flex;
        justify-content: center;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }

    .rd-logo,
    .arrow-upward {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(4deg) brightness(104%) contrast(101%);
    }

    @media (min-width: 769px) {
        .header-above {
            justify-content: end;
            padding: 5px 20px;
        }
    }

    @media (min-width: 1200px) {
        .header-above {
            justify-content: end;
            padding: 5px 60px;
        }
    }

    #block-highlightblock P {
        font-size: 12px;
        color: var(--skin-primary-color-invert-1);
        margin-bottom: 0;
        padding-left: 12px;
    }

    .arrow-upward {
        max-width: 18px;
    }

    .header-main {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 64px;
    }

    .header-main {
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0);
        transition: all ease-out 0.2s;
        padding: 0 20px;
        z-index: 9;
        position: relative;
    }

    .header-main {
        border-bottom: 1px solid var(--grey-05);
    }

    .header {
        position: sticky;
        top: 0;
        z-index: 101;
        margin-bottom: 0;
        background-color: var(--skin-primary-color-invert-1);
    }

    .ac-logo img {
        width: 190px;
        height: auto;
    }

    nav.tbm-main,
    .main-navigation {
        width: 100%;
        display: flex;
        justify-content: end;
    }

    .main-navigation {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }

    nav.tbm-main ul,
    .main-navigation ul {
        margin-bottom: 0;
    }

    .main-navigation li {
        list-style-type: none;
    }

    nav.tbm-main li a,
    .main-navigation li a,
    .tbm-link.level-1 {
        color: var(--skin-main-text-color-1);
        font-size: 16px;
        position: relative;
        padding: 0;
        font-weight: normal;
    }

    .tbm-link.level-1 {
        color: var(--acNeutrals-Black);
    }

    .main-navigation .dropdown-menu .navigation-text li a:hover::before {
        content: none;
    }

    .main-navigation .dropdown-menu .navigation-text li a:hover {
        background: var(--grey-05);
    }

    .main-navigation .dropdown-menu nav .navigation-text {
        padding: 0;
    }

    .main-navigation .dropdown-menu .navigation-text li a,
    .page-level-container.is_admin .main-navigation .dropdown-menu li a {
        padding: 5px 15px;
    }

    nav.tbm-main li a:hover,
    .main-navigation li a:hover {
        text-decoration: none;
    }

    .main-navigation nav .navigation-text,
    .tbm-item.level-1 .tbm-link-container {
        padding: 24px 18px;
    }

    .unified-account-menu {
        padding: 0 20px 0 0;
    }

    .main-navigation .navigation-text li:last-child,
    .main-navigation .navigation-text:last-child,
    .main-navigation nav .navigation-text:last-child,
    .unified-account-menu {
        padding-right: 0;
    }

    .tbm-item.level-1 {
        border-right: none;
        padding: 0;
    }

    .tbm-item.level-1.open>.tbm-submenu,
    .tbm-item.level-1>.tbm-submenu {
        max-height: calc(100vh - 110px);
        max-height: calc(100dvh - 110px);
        overflow: auto;
    }

    #block-accountblockforunifiedtheme {
        padding-left: 18px;
    }

    .unified-account-menu {
        align-items: center;
    }

    .tbm-link-container.featured .menu-icon-container+.menu-content-container,
    .footer-links ul.right-links,
    .hamburger-navigation ul.navigation-text,
    .hamburger-navigation ul.nav-level-1,
    .block-full-width-links-grey ul,
    .search-filter ul,
    .downloads-file ul {
        padding-left: 0;
    }

    nav.tbm-main .navigation-text li a:hover:before,
    .main-navigation .navigation-text li a:hover:before,
    .tbm-link.level-1:hover:before,
    .tbm-link.level-1:focus:before,
    .header-main:hover .tbm-link.level-1:hover:before,
    .header-main:hover .tbm-link.level-1:focus:before,
    nav.tbm-main li.tbm-item.active .tbm-link.level-1:before {
        border-top: 2px solid var(--grey-01);
        content: '';
        margin: 0 auto;
        position: absolute;
        top: 95%;
        left: 0;
        right: 0;
        width: 24px;
        z-index: -1;
    }

    nav.tbm-main .navigation-text li a,
    .main-navigation .navigation-text li a {
        position: relative;
        z-index: 1;
    }

    .header-main.active,
    .header-main:hover {
        background: var(--skin-primary-color-invert-1);
    }

    .tbm-link.level-1:hover,
    .tbm-link.level-1:focus {
        background: none;
    }

    .tbm-link.level-1:hover:before,
    .tbm-link.level-1:focus:before,
    nav.tbm-main li.tbm-item.active .tbm-link.level-1:before {
        border-top: 2px solid var(--acNeutrals-Black);
        transition: color ease-in-out 0.5s;
    }

    .tbm {
        background-color: transparent;
        position: static;
    }

    .tbm-submenu {
        padding: 32px;
        box-shadow: none;
    }

    .tbm-link-container {
        height: auto;
    }

    .block-tb-megamenu {
        position: static;
    }

    .tbm-submenu,
    nav .tbm-submenu .nav-tabs li {
        padding: 0;
    }

    .tbm-link-container:not(.featured) {
        background: none;
    }

    .tbm-link-container:not(.featured) {
        background: none;
    }

    .user-section {
        display: none;
    }

    .hamburger-navigation .user-section {
        display: block;
    }

    .unified-account-menu .user-section .dropdown-toggle:after {
        margin-left: 0;
        font-size: 14px;
    }

    .submenu-sm .tbm-submenu {
        max-width: 300px;
        min-width: 200px;
        width: -moz-fit-content;
        width: fit-content;
        white-space: nowrap;
    }

    .submenu-sm .tbm-submenu .tbm-row {
        display: block;
    }

    .submenu-sm ul.tbm-subnav.level-1 {
        display: block;
    }

    .submenu-sm {
        position: static;
    }

    .submenu-sm .tbm-submenu.tbm-item-child>.tbm-row {
        padding: 0 20px;
    }

    .submenu-sm .tbm-subnav>.tbm-item.level-2 .tbm-link-container,
    .submenu-sm .tbm-column.span12 .tbm-subnav>.tbm-item.level-2 {
        padding: 5px 0;
    }

    .submenu-sm .tbm-column.span12 .tbm-subnav>.tbm-item.level-2 {
        padding: 0;
    }

    .unified-account-menu .user-section {
        padding-right: 15px;
        padding-bottom: 10px;
    }

    .hamburger-navigation .unified-account-menu .user-section {
        margin: 0;
        padding: 0;
    }

    .unified-account-menu .shop-section {
        margin-right: 10px;

        img {
            height: 14px;
        }
    }

    .cart-ico {
        width: 22px;
        position: relative;
    }

    .cart-ico img {
        top: -2px;
    }

    .shop-section .cart-ico .js-basket-count {
        height: 14px;
        width: 13px;
        font-size: 10px;
        right: -3px;
        border-radius: 100%;
    }

    .unified-account-menu .user-section .btn-group {
        vertical-align: top;
        cursor: pointer;
    }

    .unified-account-menu .user-section .btn-group button {
        border: none;
        background: none;
        position: relative;
        top: 5px;
    }

    .user-section:hover .dropdown-menu {
        display: block;
        position: absolute;
        left: -20px;
        top: 34px;
    }

    .unified-account-menu .user-section .dropdown-toggle:not(:focus-visible) {
        color: var(--grey-01);
    }

    .tbm-column-inner,
    ul.tbm-subnav.level-2.items-1,
    ul.tbm-subnav.level-2.items-1>.level-3,
    ul.tbm-subnav.level-2.items-1 .featured {
        height: 100%;
        width: 100%;
    }

    .tbm-submenu.tbm-item-child>.tbm-row {
        padding: 0 64px 24px;
    }

    nav.tbm-main ul {
        display: flex;
        flex-wrap: wrap;
    }

    .tbm-subnav>.tbm-item {
        width: 100%;
    }

    .tbm-column.span12 .tbm-subnav>.tbm-item.level-2 {
        flex-basis: 25%;
        padding-right: 40px;
    }

    .tbm-subnav>.tbm-item.level-2 .tbm-link-container:not(.featured) {
        border-bottom: 1px solid var(--grey-05);
        padding: 10px 0;
    }

    .tbm-subnav>.tbm-item.level-2 .menu-link-container {
        flex: 1;
        flex-grow: 1;
    }

    .tbm-subnav>.tbm-item.level-2 .menu-content-container {
        flex-basis: 100%;
    }

    .tbm-item.level-1:hover>.tbm-submenu {
        display: block;
    }

    .unified-account-menu .search-icon img {
        max-width: 20px;
        height: 20px;
    }

    @media (max-width: 768.98px) {
        #block-highlightblock {
            display: none;
        }
    }

    @media (max-width: 991.98px) {
        .page-level-container {
            overflow-x: clip;
        }

        .tbm.tbm--mobile .tbm-collapse {
            left: 0;
            padding-top: 0;
        }

        #block-accountblockforunifiedtheme {
            padding-left: 0;
        }

        .header-above {
            display: none;
        }

        .ac-logo img {
            width: 150px;
        }
    }

    @media (min-width: 769px) {
        .header-main {
            min-height: 72px;
        }
    }

    @media (min-width: 992px) {
        .ac-logo {
            background: #fff;
            border-radius: 0 0 145px 0;
            padding: 20px 38px 25px 30px;
            font-size: 18px;
        }

        .page-level-container {
            background-repeat: no-repeat;
            background-size: #{$page-max-width} 400px;
            border-radius: 30px 30px 0 0;
        }

        .header {
            background: rgba(255, 255, 255, 0.3);
            -webkit-backdrop-filter: blur(47px);
            backdrop-filter: blur(47px);
        }

        .header-main.active,
        .header-main:hover {
            background: none;
        }

        .main-navigation {
            justify-content: end;
        }

        .unified-account-menu {
            padding: 0;
            margin-top: -5px;
        }

        .user-section {
            display: block;
            margin-right: 8px;
        }

        .search-section {
            margin-left: 8px;
        }

        .unified-account-menu .user-section img {
            height: 14px;
        }
    }


    @media (min-width: 1200px) {
        .header-main {
            padding: 0 60px;
            padding-left: 0;
        }

        .ac-logo {
            padding-left: 60px;
        }
    }

    .tbm.tbm--mobile {
        display: none;
    }

    .hamburger-navigation nav {
        background-color: transparent;
        height: 50px;
        padding: 0;
        display: flex;
        align-items: center;
        position: static;
    }

    .hamburger-navigation nav .m-menu__checkbox {
        display: none;
    }

    .hamburger-navigation nav label.m-menu__toggle {
        cursor: pointer;
    }

    .hamburger-navigation nav label.m-menu__toggle svg {
        stroke: var(--grey-01);
        height: 28px;
        display: block;
    }

    .hamburger-navigation nav label.m-menu__toggle svg {
        position: relative;
        left: -7px;
        top: -3px;
    }

    .hamburger-navigation nav label.m-menu__toggle svg line:nth-child(2n) {
        display: none;
    }

    .hamburger-navigation nav .m-menu {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100vw;
        width: 100vw;
        height: 100vh;
        height: 100dvh;
        transform: translate3d(-100vw, 0, 0);
        transition: transform 0.35s;
        z-index: 1;
        background-color: #fff;

        &:has(.highlight-hamburger > *) .m-menu {
            height: calc(100vh - 96px);
            height: calc(100dvh - 96px);
        }
    }

    .hamburger-navigation nav .m-menu__overlay {
        z-index: 1;
        display: block;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .hamburger-navigation nav .m-menu__overlay:after,
    .hamburger-navigation nav .m-menu__overlay:before {
        content: '';
        height: 2px;
        width: 14px;
        background: var(--grey-01);
        position: absolute;
        right: 20px;
        top: calc(var(--header-height)/2);
    }

    .hamburger-navigation nav .m-menu__overlay:after {
        transform: rotate(45deg);
    }

    .hamburger-navigation nav .m-menu__overlay:before {
        transform: rotate(-45deg);
    }

    .hamburger-navigation nav .m-menu__header {
        color: var(--grey-01);
        padding: 0;
        height: var(--header-height);
        display: flex;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        align-items: center;
        border-bottom: 1px solid var(--grey-05);
    }

    .hamburger-navigation nav .m-menu__header span {
        font-size: 16px;
        text-align: left;
        width: 100%;
    }

    .hamburger-navigation nav .m-menu ul li {
        position: static;
        list-style: none;
    }

    .hamburger-navigation nav .m-menu ul li {
        padding: 18px 20px 0;
    }

    .hamburger-navigation nav .m-menu ul li a:not(.language-link),
    .hamburger-navigation nav .m-menu ul li label:not(.m-menu__overlay),
    .hamburger-navigation nav .m-menu ul li i {
        display: block;
        text-align: left;
        text-decoration: none;
        color: var(--grey-01);
        cursor: pointer;
        font-size: 1rem;
        border-bottom: 1px solid var(--grey-05);
        position: relative;
        align-items: center;
        padding-bottom: 18px;
    }

    .m-menu__header .m-menu__toggle:before {
        content: '\279C';
        position: absolute;
        display: inline-block;
        height: 10px;
        width: 10px;
        right: 5px;
    }

    .hamburger-navigation nav .m-menu ul li label.a-label__chevron::after,
    .hamburger-navigation nav .m-menu ul li a.a-label__chevron::after,
    .hamburger-navigation nav .m-menu ul li i.a-label__chevron::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        top: 8px;
        right: 15px;
        width: 7px;
        height: 7px;
        border-bottom: 2px solid;
        border-left: 2px solid;
        transform: rotate(225deg);
    }

    .hamburger-navigation nav .m-menu .m-menu label.m-menu__toggle {
        display: flex;
        border-bottom: 0;
        padding: 0;
        box-sizing: content-box;
        margin-left: 45px;
    }

    .hamburger-navigation nav .m-menu__checkbox:checked~.m-menu {
        transform: translate3d(0, 0, 0);
    }

    .hamburger-navigation .menu-icon-container+.menu-content-container p,
    .highlight-hamburger p {
        margin-bottom: 0;
    }

    .submenu-view-all {
        padding: 0 20px 0;
        align-items: center;
        justify-content: space-between;
        background: var(--grey-06);
        line-height: 60px;
        font-family: var(--skin-medium-font);
        color: var(--grey-01);
    }

    .hamburger-navigation nav .m-menu ul li .submenu-view-all a {
        border: none;
        font-size: 16px;
        padding: 0;
        font-family: var(--skin-header-font);
        color: var(--skin-primary-color-1);
    }

    .hamburger-navigation nav .m-menu ul li .submenu-view-all a:hover {
        text-decoration: underline;
    }

    .m-menu__header label.m-menu__toggle {
        position: relative;
    }

    .m-menu__header label.m-menu__toggle+span {
        padding-left: 45px;
    }

    .m-menu__header .m-menu__toggle:before {
        left: -20px;
        top: 0;
        transform: rotateY(-180deg);
    }

    .hamburger-navigation .nav-item a {
        border: none;
        padding: 0;
    }

    .hamburger-navigation .nav-item .menu-content-container p.menu-title {
        margin-bottom: 0;
    }

    .hamburger-navigation nav.menu--account {
        height: auto;
        width: 100%;
        display: block;
    }

    .hamburger-navigation .unified-account-menu {
        display: block !important;
        padding: 0;
    }

    .hamburger-navigation .unified-account-menu .navigation-text li {
        padding: 0;
        padding-top: 18px;

        &.my-account-item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-bottom: 18px;
            border-bottom: 1px solid var(--grey-05);

            svg {
                color: var(--grey-01);
            }

            .nav-link {
                flex-grow: 1;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    .hamburger-navigation .unified-account-menu .navigation-text li:first-child,
    .hamburger-navigation .user-section li:first-of-type {
        padding-top: 0;
    }

    ul[data-region='navigation_hamburger'] {
        overflow: auto;
        height: calc(100vh - var(--header-height) - 60px);
        height: calc(100dvh - var(--header-height) - 60px);
        justify-content: flex-start !important;
        display: block;
        border-top: 1px solid var(--grey-05);
        padding-left: 0;

        // If the blue box at the bottom is visible, calculate the new max height
        &:has(~ .highlight-hamburger > *) {
            height: calc(100vh - var(--header-height) - 96px - 60px);
            height: calc(100dvh - var(--header-height) - 96px - 60px);
        }
    }

    .highlight-hamburger {
        position: relative;
        bottom: 0;
        width: 100%;
        background: var(--skin-primary-color-1);
        color: var(--skin-primary-color-invert-1);
        padding: 32px 20px;
        display: flex;
        justify-content: space-between;

        &:not(:has(*)) {
            display: none;
        }
    }

    .highlight-hamburger img.arrow-upward,
    #block-highlightblock img.arrow-upward {
        margin-left: 5px;
    }

    .highlight-hamburger p {
        font-size: 12px;
        height: 32px;
        align-content: center;
    }

    @media (min-width: 992px) {
        .hamburger-navigation nav {
            display: none;
        }
    }

    .js-basket-container {
        position: relative;
    }

    .js-basket-link {
        text-decoration: none;
    }

    .js-basket-link img {
        max-width: none;
    }

    .js-basket-count {
        position: absolute;
        bottom: 14px;
        left: 9px;
        font-size: 12px;
        color: #fff;
        min-height: 12px;
        border-radius: 50%;
        aspect-ratio: 1/1;
        padding: 0 3px 0 3px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--skin-primary-color-1);
        top: -4px;
    }

    .text-right {
        text-align: right;
    }

    form {
        font-size: 16px;
        max-width: 872px;
        margin: auto;
    }

    form.views-exposed-form {
        max-width: unset;
    }

    input,
    progress {
        accent-color: var(--skin-primary-color-1);
    }

    form .form-control {
        padding: 0.7rem 0.8rem;
        color: var(--skin-main-text-color-1);
    }

    .form-control:focus {
        border: 1px solid var(--skin-primary-color-1);
        box-shadow: none;
    }

    .form-actions {
        margin: auto;
        text-align: center;
    }

    .container-fluid.search-container.opened form .form-actions {
        flex-shrink: 11;
    }

    .search-container.opened .search-container-input input[type='text'] {
        padding: 11px 30px;
        color: var(--grey-03);
        background: none;
        border-radius: 40px;
    }

    .container-fluid.search-container.opened {
        border-right: 0;
        border-left: 0;
    }

    .search-icon {
        border: 0;
    }

    .search-toggle .search-icon.icon-close {
        display: none;
    }

    .search-toggle.opened .search-icon.icon-close {
        display: inline-block;
        text-align: right;
    }

    .search-container {
        position: relative;
        transition: all 0.3s;
        max-height: 0;
        overflow: hidden;
    }

    .search-container.opened {
        max-height: 100px;
    }

    .search-container input[type='text'] {
        outline: none;
        background-color: inherit;
        border: 0;
        width: 95%;
    }

    .search-container .search-icon {
        vertical-align: middle;
    }

    .container {
        position: relative;
    }

    .search-container {
        position: absolute;
    }

    .container-fluid.search-container.opened form {
        max-width: #{$page-max-width};
        display: flex;
        border: 1px solid var(--grey-05);
        border-radius: 40px;
        background: var(--grey-06);
        position: relative;
    }

    .container-fluid.search-container.opened form div {
        align-self: end;
        justify-self: center;
    }

    button.search-icon {
        background: none;
        float: right;
    }

    .search-container-input {
        padding: 10px 20px;
        border-bottom: 1px solid var(--grey-05);
    }

    .container-fluid.search-container.opened {
        top: 100%;
        position: absolute;
        left: 0;
        background: var(--skin-primary-color-invert-1);
        height: auto;
    }

    .search-toggle.opened .search-icon.icon-search {
        display: none;
    }

    .search-toggle.opened .search-icon.icon-close {
        display: block;
        float: right;
    }

    .search-toggle .search-icon.icon-close {
        display: none;
    }

    .search-container .form-control {
        padding: 11px 30px;
        color: #28282c;
        background: var(--grey-06);
        border-radius: 40px;
        font-size: 16px;
    }

    .container-fluid .search-toggle {
        margin-left: 12px;
    }

    .search-container.opened .form-control:focus {
        border: none;
    }

    .header .search-container {
        overflow: visible;
    }

    .header .search-container {
        overflow: visible;
    }

    .search-container .search-container-input {
        display: none;
    }

    .search-container.opened .search-container-input {
        display: block;
    }

    @media (min-width: 769px) {
        .search-container-input {
            padding: 10px 60px;
        }
    }

    .search-container.opened .form-actions button {
        padding: 0;
        min-width: 50px;
    }

    .search-container.opened .form-actions button img {
        padding-right: 0px;
    }

    .product-image {
        margin: auto;
        text-align: center;
        max-width: 80%;
    }

    @media (min-width: 769px) {
        .product-image {
            text-align: right;
        }
    }

    .user-container svg {
        color: transparent;
    }
}

.language-switcher {
    ul {
        display: flex;
        margin: 0;
        padding-left: 0;
        text-transform: capitalize;
    }

    ul li {
        list-style-type: none;
        padding: 0px 6px;
        line-height: 0.9;
        align-self: center;
        justify-self: center;
    }

    ul li a {
        color: var(--skin-primary-color-invert-1);
        font-size: 0.75rem;
    }

    ul li:after {
        content: "|";
        display: inline-block;
        color: var(--skin-primary-color-invert-1);
        font-size: 0.6875rem;
        margin-top: 5px;
        padding-left: 5px;
        position: absolute;
    }

    li:last-child:after {
        display: none;
    }

    .active {
        text-decoration: underline;
    }
}

.language-link.is-active,
.language-link:hover {
    border-bottom: 1px solid;
    text-decoration: none;
}

.hamburger-navigation nav .m-menu .language-switcher ul li {
    padding: 0px 6px;
    line-height: 32px;
}

.hamburger-navigation .language-switcher ul li:after {
    margin-top: 1px;
}

.mobile-logo {
    @include media-breakpoint-down(md) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
