.unified-header .modal.menu-modal {
    position: absolute;
    width: 100%;
    max-width: $page-max-width;
    left: 0;
    top: calc(var(--header-height-menu-only) - 1px);
    overflow: visible;
    padding-right: 0 !important;

    &.show {
        display: block;
    }

    .modal-dialog {
        max-width: none;
    }

    .modal-content {
        padding-inline: 32px 60px;
        border-radius: 0;
        border: 1px solid var(--grey-04);
    }

    .modal-header {
        border-bottom: none;

        .menu-close {
            position: absolute;
            right: 32px;
            top: 32px;
            background: url('../../images/Cross.svg') no-repeat center;
            height: 20px;
            width: 20px;
            cursor: pointer;
            z-index: 1;
        }
    }

    .menu-modal-body {
        position: relative;
        min-height: 430px;
        max-height: calc(90vh - var(--header-height-menu-only));
        max-height: calc(90dvh - var(--header-height-menu-only));
        display: flex;
        flex-direction: row;
        overflow: auto hidden;
    }

    .menu-submenu-list {
        display: none;
        flex-shrink: 0;
        flex-direction: column;
        padding-inline: 0;
        margin: 22px 0 32px 32px;
        border-right: 1px solid var(--grey-04);
        overflow: hidden auto;

        &:first-of-type {
            display: flex;
            margin: 22px 0 32px 16px;
        }

        &.menu-item--active {
            display: flex;
        }

        &__title,
        &__item {
            width: 300px;
            padding-right: 30px;
            cursor: pointer;

            .tbm-link {
                display: block;
                position: relative;
                line-height: 1.5rem;
                padding: 16px 30px 16px 10px;
            }

            &.disable-link {
                pointer-events: none;
            }
        }

        &__title {
            .tbm-link {
                font-family: var(--skin-medium-font);
                font-size: 20px;
                letter-spacing: 0.2px;
                padding: 10px 0 10px 10px;
            }
        }

        &__title .tbm-link:hover,
        &__item .tbm-link:hover,
        &__item .tbm-link.menu-item--active {
            background-color: var(--color-light-blue);
        }

        .submenu-link .tbm-link::after {
            content: "";
            display: block;
            box-sizing: border-box;
            position: absolute;
            top: calc(50% - 5px);
            right: 15px;
            width: 7px;
            height: 7px;
            border-bottom: 2px solid;
            border-left: 2px solid;
            transform: rotate(225deg);
        }
    }
}

.unified-header .modal.menu-modal.short {
    width: auto;
    left: unset;

    .modal-content {
        padding-inline: 0;
    }

    .menu-submenu-list {
        min-width: 225px;
        width: max-content;
        max-width: 300px;
        border-right: 0;
        padding-left: 9px;

        &__title,
        &__item {
            padding-right: 27px;
            width: unset;
        }
    }

    .menu-modal-body {
        min-height: unset;
    }
}
