@import "~base/components/common";

html, body {
    // Fixes bootstrap adding unwanted padding right when a modal is opened
    padding-right: 0 !important;
}

body {
    position: relative;
    max-width: #{$page-max-width};
    margin: 0 auto;
}

.error.continue-shopping {
    margin-bottom: 0;
}
