@import "~base/components/spinner";

.veil {
    width: 100vw;
    position: fixed;
}

.spinner {
    margin-top: calc(#{$spinner-size} / 2 * -1);
    margin-left: calc(#{$spinner-size} / 2 * -1);
}
