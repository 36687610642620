@import "~base/components/tooltip";

.info-icon {
    margin-left: 0.25rem;
    outline: 0;
    font-family: monospace;

    @include media-breakpoint-down(lg) {
        position: static;
    }

    .tooltip {
        background-color: var(--skin-primary-color-1);
        padding: 0.5rem;
        border-radius: 10px;
        bottom: 24px; // Tooltip icon height

        @include media-breakpoint-down(lg) {
            min-width: 100%;
            max-width: none;
            margin: 0 0 0.5rem;
            left: 0;
        }

        @include media-breakpoint-up(xl) {
            width: max-content;
            min-width: auto;
            max-width: 320px;
            left: 50%;
            transform: translateX(-50%);
            margin: 0 0 8px;

            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                bottom: -8px;
                left: calc(50% - 8px);
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid var(--skin-primary-color-1);
                margin: 0;
            }
        }
    }

    .icon.small-icon {
        display: block;
        width: 1.25rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
    }
}

*:has(> .info-icon) {
    @include media-breakpoint-down(lg) {
        position: relative;
    }
}
