@import "~base/bootstrapOverrides";

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-inline: $container-padding-x;
    max-width: none;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
        padding-inline: 60px;
    }
}

[data-toggle=collapse] {
    &[aria-expanded=false] {
        .swap-text__show {
            display: inline;
        }

        .swap-text__hide {
            display: none;
        }
    }

    &[aria-expanded=true] {
        .swap-text__show {
            display: none;
        }

        .swap-text__hide {
            display: inline;
        }
    }
}

.modal-footer>* {
    margin: 0;
}

.btn {
    &:focus,
    &.focus {
        outline: none;
        box-shadow: none;
    }
}
