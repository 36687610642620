@import "~base/components/notification";

.warning-message {
    &.card {
        flex-direction: row;
        align-items: stretch;
        text-align: left;
        background-color: var(--color-primary-transparency);
    }

    &__icon,
    &__content,
    &__text,
    &__cta {
        display: flex;
        align-items: center;
    }

    &__content {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding: 16px;
        gap: 8px;
    }

    &__icon {
        background-color: var(--skin-primary-color-1);

        i {
            font-size: 2rem;
            padding: 16px;
        }

        @include media-breakpoint-up(sm) {
            border-radius: 20px 0 0 20px;
        }
    }

    &__text {
        flex: 40% 1 0;
        align-self: stretch;
    }

    &__cta {
        flex: auto 0 0;
        justify-content: center;
    }
}

.top-banner {
    display: flex;
    justify-content: center;
    width: 100vw;
    max-width: $page-max-width;
    margin-left: -$container-padding-x;
    margin-bottom: 0;
    text-align: left;
    background-color: var(--color-primary-transparency);

    @include media-breakpoint-up(md) {
        margin-left: -60px;
    }

    .warning-message__icon {
        background-color: transparent;

        i {
            color: var(--skin-primary-color-1);
        }
    }

    .warning-message__content {
        width: auto;
        padding: 8px 16px 8px 0;
    }
}
