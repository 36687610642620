@import "~base/components/footer";
@import "toastMessage";

#footer {
    background-color: #FFF;

    .icon-box {
        text-align: center;
        padding: 0.2em 0.35em;
        font-size: 20px;
    }

    .footer-main {
        padding-top: 90px;
        padding-bottom: 24px;
        border-top: 1px solid var(--grey-05);
        border-bottom: 1px solid var(--grey-05);

    }

    .flag {
        width: 32px;
        height: 23px;
        display: inline-block;
        border-radius: 5px;
    }

    .country-block-flag {
        display: inline-flex;
        align-items: center;
        line-height: 0.3;
    }

    .country-flag {
        margin-right: 10px;
    }

    .country-text {
        padding-left: 0;
        font-size: 14px;
    }

    .roche-logo {
        padding: 2px 0 5px;
    }

    .country-listing {
        background-color: var(--skin-primary-color-invert-1);
        z-index: 99;
        margin: auto;
        text-align: center;
        padding: 20px 0;
    }

    .copyright-end {
        margin-left: auto;
    }

    .footer-copyrights {
        font-size: 12px;
        color: var(--grey-03);
    }

    .footer-copyright-limited {
        text-align: end;
    }

    .first-block-footer-desktop {
        line-height: 3;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    .first-block-footer-mobile {
        display: none;

        @include media-breakpoint-down(xs) {
            display: block;
            margin-bottom: 12px;
            margin-top: 1rem
        }
    }

    .block-footer-mobile-bottom {
        display: none;

        @include media-breakpoint-down(xs) {
            display: inline-block;
        }
    }

    .chevron-locale-selector {
        font-size: 9px;
        color: var(--grey-03);
        padding-left: 4px;
    }

    .country-name {
        color: var(--grey-03);
    }
}

.email-signup-message {
    @include custom-toast-message;
}
