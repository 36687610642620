@import "~base/productCard";

.item-image img.product-image {
    aspect-ratio: 1 / 1;
    max-height: none;
    background: var(--skin-image-background-color-1);
    clip-path: circle(50% at 50% 50%);
    object-fit: contain;
    padding: 10%;
}

.line-item-unit-price,
.line-item-quantity,
.line-item-total-price {
    line-height: 1.1;
}

.line-item-total-text {
    display: inline-block;
    padding-block: 8px 6px;
}

.line-item-quantity .line-item-pricing-info {
    display: flex;
    flex-direction: column;
}
