@import "~base/components/productTiles";

.product-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    @include media-breakpoint-down(md) {
        min-height: 19.6875em;
    }

    @include media-breakpoint-down(sm) {
        min-height: 13.4375em;
    }

    .image-container {
        overflow: hidden;
        padding: 15px 10px 0;

        @include media-breakpoint-down(sm) {
            padding-inline: 0;
        }
    }

    .tile-image {
        aspect-ratio: 1 / 1;
        object-fit: contain;
        background: var(--skin-image-background-color-1);
        padding: 10%;
        clip-path: circle(50% at 50% 50%);
    }

    .tile-body {
        text-align: center;
        padding-bottom: 15px;
    }

    .subscription-price {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 4px;

        .price {
            color: #9B9B9B;
        }
    }

    .info-icon {
        margin-left: 0;

        // Padding for fixing subscription-price alignment
        padding-right: 1px;
    }
}
